<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.create_time')"
                :placeholder="$t('labels.create_time')"
                name="created_at"
                sort-name="created_at"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="tracking_id"
                sort-name="tracking_id"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.customer_order_id')"
                :placeholder="$t('labels.customer_order_id')"
                name="customer_order_id"
                sort-name="customer_order_id"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.delivery_order_id')"
                :placeholder="$t('labels.delivery_order_id')"
                name="delivery_order_id"
                sort-name="delivery_order_id"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                has-sort
                :sorting="filters.sort_by"
                :options="statusOptions"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :label="$t('labels.find_goods_customer_status')"
                :placeholder="$t('labels.find_goods_customer_status')"
                name="customer_status"
                sort-name="customer_status"
                has-sort
                :sorting="filters.sort_by"
                :options="customerStatusOptions"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              {{ $t("labels.sku") }}
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              {{ $t("labels.barcode") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.missing_quantity") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.found_quantity") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.remaining_quantity") }}
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="width: 99px"
            ></th>
          </tr>
        </thead>

        <tbody v-for="(item, index) in items" :key="`i_${index}_${item.id}`">
          <tr
            class="text-center"
            v-for="(child, ck) in item.children"
            :key="`c_${ck}_${item.sku}`"
          >
            <td v-if="ck === 0" :rowspan="item.children.length">
              {{ item.warehouse_code }}
            </td>
            <td v-if="ck === 0" :rowspan="item.children.length">
              {{ formatDateTime(item.created_at) }}
            </td>
            <td v-if="ck === 0" :rowspan="item.children.length">
              <OrderTracking :tracking-id="item.tracking_id" />
              <div v-if="item.request_cancel" class="mt-1">
                <v-chip color="error" small>{{
                  $t("labels.cancelled")
                }}</v-chip>
              </div>
            </td>
            <td v-if="ck === 0" :rowspan="item.children.length">
              {{ item.customer_order_id }}
            </td>
            <td v-if="ck === 0" :rowspan="item.children.length">
              {{ item.delivery_order_id }}
            </td>
            <td v-if="ck === 0" :rowspan="item.children.length">
              {{ $t(`labels.find_goods_status_${item.status}`) }}
            </td>
            <td v-if="ck === 0" :rowspan="item.children.length">
              {{
                $t(`labels.find_goods_customer_status_${item.customer_status}`)
              }}
            </td>
            <td>{{ child.sku }}</td>
            <td>
              <GoodsCode :code="child.barcode" />
            </td>
            <td>{{ child.missing_quantity }}</td>
            <td>{{ child.finded_quantity }}</td>
            <td>{{ child.remaining_quantity }}</td>
            <td>
              <template
                v-if="
                  item.customer_status === 0 &&
                  item.request_cancel === 0 &&
                  item.status === 3
                "
              >
                <v-btn
                  color="primary"
                  outlined
                  x-small
                  block
                  @click="changeCustomerStatus(item, 1)"
                  class="mb-1"
                >
                  {{ $t("labels.find_goods_customer_status_1") }}
                </v-btn>
                <v-btn
                  color="warning"
                  outlined
                  x-small
                  block
                  @click="changeCustomerStatus(item, 2)"
                  class="mb-1"
                >
                  {{ $t("labels.find_goods_customer_status_2") }}
                </v-btn>
                <v-btn
                  color="error"
                  outlined
                  x-small
                  block
                  @click="changeCustomerStatus(item, 3)"
                >
                  {{ $t("labels.find_goods_customer_status_3") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { FIND_GOODS_CUSTOMER_STATUS, FIND_GOODS_STATUS } from "@/libs/const";
import { debounce } from "lodash/function";
export default {
  name: "FindManager",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
    GoodsCode: () => import("@/components/common/GoodsCode"),
  },
  data: () => ({
    tabs: [
      {
        text: "Chưa tìm",
        status: 1,
        img: require("@/assets/internal_requests/1_Cho_xu_Ly.png"),
      },
      {
        text: "Tìm bổ sung",
        status: 2,
        img: require("@/assets/internal_requests/2_Dang_xu_ly.png"),
      },
      {
        text: "Không thấy",
        status: 3,
        img: require("@/assets/internal_requests/3_Cho_xac_nhan.png"),
      },
      {
        text: "Đã thấy",
        status: 4,
        img: require("@/assets/internal_requests/4_Da_hoan_thanh.png"),
      },
      {
        text: "Đóng yêu cầu",
        status: 5,
        img: require("@/assets/internal_requests/5_ngung_xu_ly.png"),
      },
    ],
    page: 1,
    totalPage: 1,
    counts: {},
    items: [],
    filters: {},
    isLoading: false,
  }),
  computed: {
    customerStatusOptions() {
      return [...FIND_GOODS_CUSTOMER_STATUS].map((value) => ({
        value,
        text: this.$t(`labels.find_goods_customer_status_${value}`),
      }));
    },
    statusOptions() {
      return [...FIND_GOODS_STATUS].map((value) => ({
        value,
        text: this.$t(`labels.find_goods_status_${value}`),
      }));
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    onTabChange(tab) {
      this.filters = { ...this.filters, status: tab };
    },
    async changeCustomerStatus(item, status) {
      if (
        !confirm(
          this.$t("messages.are_you_sure_process_missing_order", {
            action: this.$t(`labels.find_goods_customer_status_${status}`),
            tracking: item.tracking_id,
          })
        )
      ) {
        return true;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-find-change-status", {
          id_goods_issue_detail: item.id_goods_issue_detail,
          customer_status: status,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-find-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.counts = { ...data.counts };
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
  },
};
</script>

<style scoped></style>
